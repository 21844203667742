<!-- <template>
    
</template>
<script>
export default {};
</script>

<style scoped>
.vs-collapse-item--content {
  height: 100%;
  max-height: 14444px;
}
</style> -->

<style>
.reportrange-text {
  min-height: 40px !important;
}
</style>
<template>
  <vx-card title="Invalid Invoice">
    <div class="vx-row">
      <vs-tabs>
        <vs-tab label="Trade">
          <invoice-trade></invoice-trade>
        </vs-tab>
        <vs-tab label="Non Trade">
          <div class="tab-text">
            <non-trade></non-trade>
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
  </vx-card>
</template>
<script>
import InvoiceTrade from "./po";
import NonTrade from "./po_nt";

export default {
  components: {
    InvoiceTrade,
    NonTrade
  },
};
</script>
