<template>
    <!-- <vx-card> -->
        <vs-tabs>
            <vs-tab label="Invalid">
                <div class="tab-text">
                    <invalid></invalid>
                </div>
            </vs-tab>
            <vs-tab label="Rejected">
                <div class="tab-text">
                    <rejected></rejected>
                </div>
            </vs-tab>
        </vs-tabs>
    <!-- </vx-card> -->
</template>
<script>
    import Rejected from "./rejected";
    import Invalid from "./invalid";
    export default {
        components: {
            Rejected,
            Invalid,
        },
    };
</script>